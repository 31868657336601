import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fNumber(number) {
  return numeral(number).format();
}

export function fCurrency(number, currency = 'EUR') {
  let format = number ? `${numeral(number).format('0.00')} ${currency}` : '';

  if (currency === 'EUR') {
    format = number ? `${numeral(number).format('0.00')} €` : '';
  }
  if (currency === 'SEK') {
    format = number ? `${numeral(number).format('0.00')} kr` : '';
  }
  if (currency === 'GBP') {
    format = number ? `£${numeral(number).format('0.00')}` : '';
  }
  if (currency === 'USD') {
    format = number ? `$${numeral(number).format('0.00')}` : '';
  }

  return result(format, '.00');
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function fPhoneNumber(number) {
  if (!number) {
    return '';
  }
  return number.replace(/\D+/g, '').replace(/(\d{3})(\d{2})(\d{3})(\d*)/, '+$1 $2 $3 $4');
}
